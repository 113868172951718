import { useEffect, useMemo } from "react";
import useVingZoomMeeting from "./useVingZoomMeeting";
import useRTDBVing from "./useRTDBVing";

function App() {
  const query = useQuery();
  const meetingId = query.get("meetingId");
  const passcode = query.get("passcode");
  const name = query.get("name");
  const vingMeetingId = query.get("vingMeetingId");
  const roomId = query.get("roomId");
  const {
    hostMuteStatus,
    hostMuteVideo,
    joinMeetingError,
    initMeetingError,
    dbStatus,
    hideRoomId,
    hideNonVideoParticipants,
  } = useRTDBVing(vingMeetingId || "testving");

  const { meetingConfig, initAndJoin, hideNonVideoParticipantsFun } =
    useVingZoomMeeting(meetingId, passcode, name, {
      hostMuteStatus,
      hostMuteVideo,
      joinMeetingError,
      initMeetingError,
    });

  useEffect(() => {
    const overlayWrapper = document.querySelector(".overlay-wrapper");
    const overlayRoomId = document.getElementById("overlay-room-id");

    if (!hideRoomId && roomId) {
      overlayWrapper.classList.add("active");
      overlayRoomId.innerHTML = roomId;
    } else {
      overlayWrapper.classList.remove("active");
    }
  }, [roomId, hideRoomId]);

  useEffect(() => {
    console.log("hideNonVideoParticipants-app.js",hideNonVideoParticipants);
      hideNonVideoParticipantsFun(hideNonVideoParticipants);
  }, [hideNonVideoParticipants]);

  useEffect(() => {
    if (meetingConfig.signature && dbStatus.isConnected) {
      initAndJoin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingConfig.signature, dbStatus.isConnected]);
  return <></>;
}

function useQuery() {
  const { search } = window.location;

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default App;
