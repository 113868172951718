import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref, update } from "firebase/database";
import { useEffect, useMemo, useState } from "react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_RTDB_API_KEY,
  authDomain: process.env.REACT_APP_RTDB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_RTDB_DB_URL,
  projectId: process.env.REACT_APP_RTDB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_RTDB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_RTDB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_RTDB_APP_ID,
};

async function updateControlsData(controlsData, dbRef) {
  try {
    await update(dbRef, controlsData);
  } catch (e) {
    console.log("Error on updating controls data", e);
  }
}

function updateState(setState, update) {
  setState((prev) => ({ ...prev, ...update }));
}

function getDBRef(db, meetingId) {
  return ref(db, `/meetingControls/${meetingId}`);
}

function useRTDBVing(meetingId) {
  const [dbStatus, setDBStatus] = useState({
    isConnected: false,
    database: null,
  });
  const [hideRoomId, setHideRoomId] = useState(true);
  const [hideNonVideoParticipants,setHideNonVideoParticipants]=useState(false)

  const dbRef = useMemo(() => {
    const db = dbStatus.database;

    return db ? getDBRef(db, meetingId) : null;
  }, [dbStatus.database, meetingId]);

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);

    updateState(setDBStatus, { database: db, isConnected: true });

    if (dbRef) {
      onValue(dbRef, (snapshot) => {
        const RTDB = snapshot.val();
        if (RTDB) {
          setHideRoomId(RTDB.hideRoomId);
          setHideNonVideoParticipants(RTDB.hideNonVideoParticipants)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbRef, dbStatus.database, meetingId]);

  return {
    dbStatus,
    hideRoomId,
    hideNonVideoParticipants,
    hostMuteStatus: (value) => {
      const controlsData = { isAudioOn: !value, hostMutedStatus: value };

      updateControlsData(controlsData, dbRef);
    },
    hostMuteVideo: (value) => {
      const controlsData = {
        isVideoOn: !value,
        hostMuteVideo: value,
        disableVideoBtn: value,
      };

      updateControlsData(controlsData, dbRef);
    },
    joinMeetingError: (error) => {
      const controlsData = { joinMeetingError: !!error };
      updateControlsData(controlsData, dbRef);
    },
    initMeetingError: (error) => {
      const controlsData = { initMeetingError: !!error };
      updateControlsData(controlsData, dbRef);
    },
  };
}

export default useRTDBVing;
