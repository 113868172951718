import { useEffect, useState } from "react";
import { ZoomMtg } from "@zoomus/websdk";
import { apiProxyUrl } from "./constants";

//clicking the join audio by computer btn if exist
let joinAudioId;
function checkJoinAudioBtn() {
  const joinAudioBtn = document.querySelector(".join-audio-by-voip__join-btn");
  if (joinAudioBtn) {
    console.log(`Join Audio button found`);
    clearTimeout(joinAudioId);
    setTimeout(() => {
      console.log(`Click Join Audio button`);
      joinAudioBtn.click();
      // Application loaded. Add to queryselector to notify pupetteer to know
      var loaded = document.querySelector(".__vingLoaded");
      if (!loaded) {
        console.log(`Adding __vingLoaded to body`);
        var elm = document.createElement("div");
        elm.className = "__vingLoaded";
        document.body.appendChild(elm);
      } else {
        console.log(`__vingLoaded already exists`);
      }
    }, 4000);
  } else {
    console.log(`Join Audio button not found. Retrying after 1 sec.`);
    clearTimeout(joinAudioId);
    joinAudioId = setTimeout(checkJoinAudioBtn, 1000);
  }
}

//clicking the Start Video btn if exist
let joinVideoId;
let startedVideo = false;
function checkJoinVideoBtn() {
  if (document.querySelector("[arialabel='start sending my video']")) {
    startedVideo = true;
    //clearing timeout, once the start video btn is found
    clearTimeout(joinVideoId);
    setTimeout(() => {
      // clicking the start Video btn after 4 secs
      if (document.querySelector("[arialabel='start sending my video']")) {
        document.querySelector("[arialabel='start sending my video']").click();
        setTimeout(checkJoinVideoBtn, 2000);
      }
    }, 8000);
  } else {
    clearTimeout(joinVideoId);
    if (!startedVideo) {
      joinVideoId = setTimeout(checkJoinVideoBtn, 1000);
    }
  }
}

function changeToGalleryView() {
  let galleryViewCheckId = setInterval(() => {
    const galleryViewBtn = document.querySelector(
      '[aria-label="Gallery View"]'
    );
    if (galleryViewBtn) {
      galleryViewBtn.click();
      clearInterval(galleryViewCheckId);
    }
  }, 1000);
}

function hideUnWantedUIElements() {
  const UIElements = [
    ".sharing-entry-button-container__pop-menu",
    "[aria-labelledby='moreButton']",
    ".full-screen-widget__pop-menu", //Toogle view btns
    '[aria-label="See apps that are accessing your meeting content"]',
    ".audio-option-menu__pop-menu",
    ".video-option-menu__pop-menu",
    ".footer-chat-button__settings-drop-down-menu--checked",
    ".sharee-sharing-indicator__dropdown",
    ".ant-message"
  ];

  UIElements.forEach((ele) => {
    const element = document.querySelector(ele);
    if (element) {
      element.style.display = "none";
    }
  });

  const videoHoverPopups = document.querySelectorAll(
    ".video-avatar__avatar .avatar-more"
  );

  if (videoHoverPopups) {
    videoHoverPopups.forEach((ele) => (ele.style.display = "none"));
  }

  // Allowing the zoom to record your meeting
  document
    .querySelector('[aria-label="This meeting is being recorded"]  button')
    ?.click();
}

function getZoomSignature(props) {
  const { setMeetingConfig, meetingNumber } = props;

  const { port, protocol, hostname } = window.location;
  const tmpPort = port === "" ? "" : ":" + port;
  const avLibUrl =
    protocol + "//" + hostname + tmpPort + "/device-apps/zoom/lib";

  ZoomMtg.setZoomJSLib(avLibUrl, "/av");

  ZoomMtg.preLoadWasm();
  ZoomMtg.prepareWebSDK();
  ZoomMtg.i18n.load("en-US");
  ZoomMtg.i18n.reload("en-US");

  const payload = {
    provider: "zoom",
    meetingId: meetingNumber,
    role: 0,
  };
  fetch(`${apiProxyUrl}v1/providers/create-guest-token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((res) => {
      return res.json();
    })
    .then((result) => {
      setMeetingConfig({ ...result.result });
    })
    .catch((error) => {
      console.log("ERROR on creating meeting access token", error);
    });
}

// function hideNonVideoParticipantsFun(hideNonVideoParticipants) {
//   console.log("hideNonVideoParticipants-usevingzoom",hideNonVideoParticipants);
//   const settingsButton = document.querySelector(
//     '.footer-button-base__button[title="Settings"]'
//   );

//   if (settingsButton) {
//     settingsButton.click();
//   }

//   const videoTab = document.querySelector(".zmu-tabs__tab-bar#video");
//   if (videoTab) {
//     videoTab.click();
//   }

//   window.__checkboxes = document.querySelectorAll(".settings-video__checkbox");

//   window.__checkboxes.forEach((checkbox) => {
//     const text = checkbox
//       .querySelector(".zm-checkbox-message")
//       .textContent.trim();

//     if (text === "Hide Non-video Participants") {
//       const isCheckboxChecked = checkbox.classList.contains(
//         "zm-checkbox-checked"
//       );

//       if (
//         (hideNonVideoParticipants && !isCheckboxChecked) ||
//         (!hideNonVideoParticipants && isCheckboxChecked)
//       ) {
//         checkbox.click();
//       }
//     }
//   });

//   const closeButton = document.querySelector(".settings-dialog__close");
//   if (closeButton) {
//     closeButton.click();
//   }
// }

function hideNonVideoParticipantsFun(hideNonVideoParticipants) {
  console.log("hideNonVideoParticipants-using-zoom", hideNonVideoParticipants);

  const clickSettingsButton = async () => {
    const settingsButton = document.querySelector(
      '.footer-button-base__button[title="Settings"]'
    );

    if (settingsButton) {
      settingsButton.click();
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  };

  const clickVideoTab = async () => {
    const videoTab = document.querySelector(".zmu-tabs__tab-bar#video");
    if (videoTab) {
      videoTab.click();
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  };

  const clickCheckbox = async () => {
    window.__checkboxes = document.querySelectorAll(".settings-video__checkbox");

    window.__checkboxes.forEach((checkbox) => {
      const text = checkbox
        .querySelector(".zm-checkbox-message")
        .textContent.trim();

      if (text === "Hide Non-video Participants") {
        const isCheckboxChecked = checkbox.classList.contains("zm-checkbox-checked");

        if (
          (hideNonVideoParticipants && !isCheckboxChecked) ||
          (!hideNonVideoParticipants && isCheckboxChecked)
        ) {
          checkbox.click();
        }
      }
    });

    await new Promise(resolve => setTimeout(resolve, 1000));
  };

  const clickCloseButton = async () => {
    const closeButton = document.querySelector(".settings-dialog__close");
    if (closeButton) {
      closeButton.click();
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  };

  const executeHideNonVideoParticipants = async () => {
    await clickSettingsButton();
    await clickVideoTab();
    await clickCheckbox();
    await clickCloseButton();
  };

  executeHideNonVideoParticipants();
}






function unMuteOnJoin() {
  ZoomMtg.getCurrentUser({
    success: function ({ result }) {
      if (result?.currentUser?.muted) {
        console.log(
          "success getCurrentUser2222",
          result.currentUser,
          result?.currentUser?.muted
        );
        setTimeout(() => {
          console.log("on settimeout", result?.currentUser?.muted);
          ZoomMtg.mute({ mute: false });
        }, 6000);
      }
    },
  });
}

let meetingNotStartId;
function joinWhenMeetingNotStarted() {
  // if meeting is not started page comes, then click the joining btn.
  const joiningBtn = document.querySelector(
    ".controls.waiting-pannel-btn > button:nth-child(1)"
  );

  if (joiningBtn && joiningBtn?.textContent === "joining") {
    clearInterval(meetingNotStartId);
    setTimeout(joiningBtn?.click(), 4000);
  }
}

/**
 * variable @videoStartOnce - checkHostStopVideo function should run only after local user's video started once.
 * variable @videoStopByUser - check if the local user stops his video.
 * variable @hostStoppedVideo - check if the host stops the local user's video.
 */

let videoStopByUser = false;
let videoStartOnce = false;
let hostStoppedVideo = false;

function checkHostStopVideo({ mqttFn: { hostMuteVideo } }) {
  if (!videoStartOnce) {
    const videoStarted = document.querySelector(
      "[arialabel='stop sending my video']"
    );

    if (videoStarted) {
      videoStartOnce = true;
    }

    return;
  }

  const videoStopped = document.querySelector(
    "[arialabel='start sending my video']"
  );

  if (videoStopped && !videoStopByUser && !hostStoppedVideo) {
    // hostStoppedVideo gets true, only after sending the mqtt msg one time. else it sends msg frequently.
    hostMuteVideo(true);
    hostStoppedVideo = true;
    console.log("Host Stopped your video");
  }
}

let found = false;

function checkHostMute({ mqttFn: { hostMuteStatus, hostMuteVideo } }) {
  document.querySelectorAll("div").forEach((ele) => {
    if (ele.innerText === "The host muted you" && !found) {
      found = true;
      hostMuteStatus(true);
      setTimeout(() => {
        found = false;
      }, 5000);
    }
  });

  const unmute = document.querySelector('[aria-label="Unmute Dialog"]  button');

  if (unmute) {
    unmute.click();
    hostMuteStatus(false);
  }

  const unmuteVideo = document.querySelector(
    '[aria-label="Mute Video Modal"]  button'
  );

  if (unmuteVideo?.textContent?.includes("Start My Video")) {
    console.log("Host unmuted the video");
    unmuteVideo.click();
    hostMuteVideo(false);
    setTimeout(() => {
      hostStoppedVideo = false;
    }, 3000);
  }
}

function initAndJoin(props) {
  const { meetingNumber, meetingConfig, passcode, name, mqttFn } = props;

  window.__leaveZoomMeeting = () => {
    return new Promise((resolve, reject) => {
      ZoomMtg.leaveMeeting({ success: resolve, error: reject });
    });
  };

  window.__changeLayout = (layout) => {
    const LAYOUTS = {
      gallery: '[aria-label="Gallery View"]',
      speaker: '[aria-label="Speaker View"]',
    };

    const layoutLabel = LAYOUTS[layout];

    if (!layoutLabel) {
      console.log(`The given layout (${layout}) is not supported.`);
      return;
    }

    const layoutBtn = document.querySelector(layoutLabel);

    if (layoutBtn) {
      layoutBtn.click();
    }
  };

  window.mute = () => ZoomMtg.mute({ mute: true });

  window.unmute = () => ZoomMtg.mute({ mute: false });

  window.startLocalVideo = () => {
    if (!hostStoppedVideo) {
      document.querySelector("[arialabel='start sending my video']")?.click();

      setTimeout(() => {
        videoStopByUser = false;
      }, 3000);
    } else {
      console.log("Can't do any actions, host stopped your video.'");
    }
  };

  window.stopLocalVideo = () => {
    if (!hostStoppedVideo) {
      videoStopByUser = true;
      document.querySelector("[arialabel='stop sending my video']")?.click();
    } else {
      console.log("Can't do any actions, host stopped your video.'");
    }
  };

  ZoomMtg.init({
    leaveUrl: new URL("leave.html", window.location.href).toString(),
    disableCORP: false,
    success: function () {
      meetingNotStartId = setInterval(joinWhenMeetingNotStarted, 1000);
      ZoomMtg.join({
        meetingNumber: meetingNumber,
        userName: name,
        signature: meetingConfig.signature,
        sdkKey: meetingConfig.sdkKey,
        passWord: passcode,
        success: function (res) {
          console.log("JOINING ZOOM MEETING SUCCESS");
          unMuteOnJoin();
          checkJoinAudioBtn();
          checkJoinVideoBtn();
          changeToGalleryView();
          setInterval(() => {
            hideUnWantedUIElements();
            checkHostMute(props);
            checkHostStopVideo(props);
          }, 1000);
        },
        error: function (res) {
          console.log("ERROR ON JOINING ZOOM MEETING", res);
          mqttFn.joinMeetingError(res);
        },
      });
    },
    error: function (res) {
      console.log("ERROR ON init()", res);
      mqttFn.initMeetingError(res);
    },
  });
}

function useVingZoomMeeting(meetingNumber, passcode, name, mqttFn) {
  const [meetingConfig, setMeetingConfig] = useState({
    signature: "",
  });

  const props = {
    meetingNumber,
    passcode,
    name,
    meetingConfig,
    setMeetingConfig,
    mqttFn,
  };

  useEffect(() => {
    getZoomSignature(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    meetingConfig,
    setMeetingConfig,
    hideNonVideoParticipantsFun,
    initAndJoin: () => {
      initAndJoin(props);
    },
  };
}

export default useVingZoomMeeting;
